<template>
	<div class="app-container" style="background-color: #fff;">
		<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="活动名称" prop="activeName">
				<el-input :disabled="true" v-model="ruleForm.activityName" placeholder="最多输入20个字" maxlength="20" style="width:400px"></el-input>
			</el-form-item>
			<el-form-item label="活动时间" prop="activityTime">
				<el-date-picker :disabled="true" v-model="ruleForm.activityTime" type="datetimerange" :picker-options="pickerOptions"
				range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			<el-form-item label="商品信息" prop="activeGoodsList">
			</el-form-item>
			<el-table :data="goodsList" style="width: 100%;margin-left: 30px;">
				<el-table-column label="商品信息" width="300px">
					<template slot-scope="scope">
						<div class="product-info">
							<img v-if="scope.row.skuImgUrl" :src="scope.row.skuImgUrl" />
							<svg-icon v-else icon-class="noImgIcon" />
							<div>
								<div>
									<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
								</div>
								<div>{{scope.row.goodsCode}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="规格">
					<template slot-scope='scope'>
						<div v-for="(item,index) in scope.row.skuList" :key="index" style="margin-top: 20px;">
							<div style="margin-bottom: 30px;">
								{{item.specValues}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="销售价格">
					<template slot-scope='scope'>
						<div v-for="(item,index) in scope.row.skuList" :key="index" style="margin-top: 20px;">
							<div style="margin-bottom: 30px;">
								{{item.salePrice}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="划线价格">
					<template slot-scope='scope'>
						<div v-for="(item,index) in scope.row.skuList" :key="index" style="margin-top: 20px;">
							<div style="margin-bottom: 30px;">
								{{item.originalPrice}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="秒杀价格">
					<template slot-scope='scope'>
						<div v-for="(item,index) in scope.row.skuList" :key="index" style="margin-top: 20px;">
							<div style="margin-bottom: 30px;">
								{{item.secKillPrice}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="秒杀总库存">
					<template slot-scope='scope'>
						<div v-for="(item,index) in scope.row.skuList" :key="index" style="margin-top: 20px;">
							<div style="margin-bottom: 30px;">
								{{item.secKillStock+item.soldStock}}
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="剩余库存">
					<template slot-scope='scope'>
						<div v-for="(item,index) in scope.row.skuList" :key="index" style="margin-top: 20px;">
							<div style="margin-bottom: 30px;">
								{{item.secKillStock}}
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</el-form>
	</div>
</template>

<script>
	import {
		seckillDetail,
	} from '@/api/goods'
	import buttonPermissions from '@/components/buttonPermissions';
	export default {
		components: {
			buttonPermissions,
		},
		data() {
			return {
				ruleForm: {
					activeName: '',
					activityTime: '',
					activeTimeDuan: 0,
					activeGoodsList: [],
					timeId: null
				},
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
				timeList: [{
					timeId: 1,
					timeValue: '1点'
				}],
				loading: false,
				detailData: null,
				goodsList: []
			}
		},
		beforeMount() {
			this.getDetail();
		},
		methods: {
			async getDetail() {
				let data = {
					secKillId: +this.$route.query.id || 0
				}
				let result = await seckillDetail(data);
				if (result.success) {
					this.ruleForm.activityName = result.data.activityName;
					this.ruleForm.activityTime = [result.data.startTime, result.data.endTime];
					this.detailData = result.data;
					// var list = [];
					// result.data.goodsList.map(item => {
					// 	item.skuList.map(its => {
					// 		var stock = its.soldStock || 0;
					// 		its.totalStock = its.secKillStock + stock;
					// 		item.skuObj = its;
					// 		list.push(item);
					// 		return its;
					// 	})
					// 	return item;
					// })
					this.goodsList = result.data.goodsList;
					// console.log("--------",list)
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: result.alertMsg
					})
				}
			}
		}
	}
</script>

<style>
</style>
